$topcommercialcleaners-hover: #f1f8ff;
$topcommercialcleaners-orange: #ff8a00;
$topcommercialcleaners-blue: #134981;
$topcommercialcleaners-grey: #777777;

:export {
    green: $topcommercialcleaners-blue;
    hover: $topcommercialcleaners-hover;
    orange: $topcommercialcleaners-orange;
    grey: $topcommercialcleaners-grey;
}
