
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: flex-start;
    height: auto;
    min-height: calc(100vh - 58px);
    .form {
        box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.1);
        z-index: 5;
        width: 100%;
        padding: 30px 24px;
        max-width: 700px;
        border-radius: 10px;
        min-height: 500px;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        position: relative;

        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 30px;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 500 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: center;
            font-size: 20px !important;
            font-weight: 500 !important;
            margin-top: -20px;
            margin-bottom: 25px !important;
            @include lg {
                margin-right: -50px;
                margin-left: -50px;
            }
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            background-color: $topcommercialcleaners-orange !important;
            transition: all 0.2s ease-in-out;
            @include lg {
                max-width: 450px !important;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                max-width: 450px;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: $topcommercialcleaners-hover;
                    }
                    &:hover + label {
                        background-color: $topcommercialcleaners-hover;
                    }
                }
                label {
                    border-radius: 3px;
                    border: 1px solid $topcommercialcleaners-blue;
                    color: $topcommercialcleaners-blue;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    text-align: left;
                    justify-content: flex-start;
                    > span {
                        margin-top: 0;
                    }
                    > div {
                        margin-right: 15px;
                    }
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .checkbox-field {
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: $topcommercialcleaners-hover;
                    }
                }
                label {
                    border-radius: 3px;
                    border: 1px solid $topcommercialcleaners-blue;
                    color: $topcommercialcleaners-blue;
                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    @include md {
                        min-height: 50px;
                    }
                }
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
        @include lg {
            min-width: 400px;
            width: 60%;
            padding: 40px 40px;
            margin-bottom: 200px;
            margin-top: 50px;
        }
    }
    .image {
        z-index: 2;
        & > div {
            position: absolute !important;
            left: 0;
            bottom: 0;
            height: 300px;
            width: 100vw;
            max-width: unset !important;
            left: 50% !important;
            transform: translateX(-50%) !important;

            @include lg {
                height: 100%;
                top: 0 !important;
                left: 0%;
            }
        }

        img {
            min-height: unset !important;
            min-width: unset !important;
            width: auto !important;
            height: 100% !important;
            max-width: unset !important;
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: 2;
        background: linear-gradient(
            180deg,
            rgba(238, 251, 255, 0) 0%,
            #eefbff 100%
        );
    }
    &::after {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: 2;
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("/assets/images/background.svg");
        @include lg {
            background-size: 90vw;
        }
        @include xxl {
            background-size: 1480px;
            background-position: 50% 150px;
        }
    }
}

.offerContainer {
    position: relative;

    min-height: calc(100vh - 58px);
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        z-index: -2;
        background: linear-gradient(
            180deg,
            rgba(238, 251, 255, 0) 0%,
            #eefbff 100%
        );
    }
}
